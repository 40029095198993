import React from 'react';
import {
  BiQuestionMark,
  BiMessage,
  BiCloud,
  BiBasket,
  BiCalendarEvent,
  BiUser
} from 'react-icons/bi';

const routesConfig = [
  {
    id: 'app',
    title: 'Menu',
    messageId: 'sidebar.sample',
    type: 'group',
    children: [
      {
        id: 'DashBoard',
        title: 'DashBoard',
        messageId: 'sidebar.sample.DashBoard',
        type: 'item',
        icon: <BiUser />,
        path: '/sample/DashBoard',
      },
      {
        id: 'Chat',
        title: 'Chat',
        messageId: 'sidebar.sample.Chat',
        type: 'item',
        icon: <BiMessage />,
        path: '/sample/Chat',
      },
      {
        id: 'page-2',
        title: 'Product Feedback',
        messageId: 'sidebar.sample.page2',
        type: 'item',
        icon: <BiMessage />,
        path: '/sample/page-2',
      },
      {
        id: 'Training-Module',
        title: 'Training Module',
        messageId: 'sidebar.sample.Trainingmodule',
        type: 'item',
        icon: <BiQuestionMark />,
        path: '/sample/TrainingModule',
      },
      {
        id: 'blog',
        title: 'Blogs',
        messageId: 'sidebar.sample.blog',
        type: 'item',
        icon: <BiCloud />,
        path: '/sample/blog',
      },
      {
        id: 'Products',
        title: 'Products',
        messageId: 'sidebar.sample.Products',
        type: 'item',
        icon: <BiQuestionMark />,
        path: '/sample/Products',
      },
      {
        id: 'Meetings',
        title: 'Meeting',
        messageId: 'sidebar.sample.Meetings',
        type: 'item',
        icon: <BiBasket />,
        path: '/sample/Meetings',
      },
      {
        id: 'Calendar',
        title: 'Calendar',
        messageId: 'sidebar.sample.Calendar',
        type: 'item',
        icon: <BiCalendarEvent />,
        path: '/sample/Calendar',
      },       
    ],
  },
];
export default routesConfig;

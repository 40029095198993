import {
  GET_QUIZ,
  FETCH_SUCCESS,
  GET_SCORE,
} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export function fetchQuiz(user) {
  console.log(user);
  return (dispatch) => {
    // new Promise((resolve) => {
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    let quizlist = [];

    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('querySnapshot', querySnapshot?.data().created_by);
      console.log('created_by training module', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        if (quizlist.length === 0) {
          dispatch({type: GET_QUIZ, payload: quizlist});
          // dispatch({type: GET_EVENTS, payload: meetings});
        }
        var blogref = firebase
          .firestore()
          .collection('training_module')
          .where('ref_manager_id', '==', manager_id)
          .where('is_active', '==', '1');
        blogref.get().then((squerySnapshot) => {
          squerySnapshot.forEach((doc) => {
            console.log(doc.data());
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data(), squerySnapshot);
            quizlist.push(doc.data());
            console.log(quizlist);
            var arr_img = [];
            quizlist.map((img) => {
              var obj_arr = {
                auto_id: img.auto_id,
                created_by: img.created_by,
                training_module_name: img.training_module_name,
                is_active: img.is_active,
                // blog: img.blog,
                // blog_file: img.blog_file,
              };
              arr_img.push(obj_arr);
            });
            console.log(arr_img);

            console.log(quizlist.length === squerySnapshot.size);
            console.log(squerySnapshot.size);
            if (quizlist.length === arr_img.length) {
              console.log(quizlist);
              dispatch({type: GET_QUIZ, payload: arr_img});
            }
            // dispatch({type: GET_QUIZ, payload: quizlist})
          });
        });
      });
    });
    // });
  };
}
export function fetchNotifyQuiz(user) {
  console.log(user);
  return new Promise((resolve) => {
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    let quizlist = [];

    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('querySnapshot', querySnapshot?.data().created_by);
      console.log('created_by training module', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        var blogref = firebase
          .firestore()
          .collection('training_module')
          .where('ref_manager_id', '==', manager_id)
          .where('is_active', '==', '1');
        blogref.get().then((squerySnapshot) => {
          squerySnapshot.forEach((Tdoc) => {
            console.log(Tdoc.data());
            var trainingInfo = Tdoc.data();
            trainingInfo.id = Tdoc.data().auto_id;
            (trainingInfo.message = 'Added New Training Module'),
              firebase
                .firestore()
                .collection('brand_manager')
                .doc(manager_id)
                .get()
                .then(function (Mdoc) {
                  var data = Mdoc.data();
                  console.log(data);
                  trainingInfo.name = data.brand_manager_firstname;
                  trainingInfo.image = data.brand_manager_firstname
                    .charAt(0)
                    .toUpperCase();
                });
            // doc.data() is never undefined for query doc snapshots
            quizlist.push(trainingInfo);
            console.log('training-quiz----->', quizlist);
            console.log(quizlist.length === squerySnapshot.size);
            if (quizlist.length === squerySnapshot.size) {
              console.log(quizlist);
              resolve(quizlist);
            }
          });
        });
      });
    });
  });
}
export function getSelectedQuiz(docid) {
  return new Promise((resolve, reject) => {
    console.log('Invoked - Qizzz', docid);
    firebase
      .firestore()
      .collection('training_module')
      .doc(docid)
      .get()
      .then(function (doc) {
        var data = doc.data();
        data.id = doc.id;
        console.log(data);
        resolve(data);
      })
      .catch((err) => {
        console.log(err);
        reject(err.message);
      });
  });
}
export function addScore(event) {
  return new Promise((resolve) => {
    console.log(event);
    var docRef = firebase.firestore().collection('scorecard').doc();
    docRef.set({
      auto_id: docRef.id,
      score: event?.score,
      questions_length: event?.questions_length,
      ref_quiz_id: event?.ref_quiz_id,
      created_at: firebase.firestore.Timestamp.fromDate(new Date()),
      updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
      created_by: firebase.auth().currentUser.uid,
      updated_by: firebase.auth().currentUser.uid,
    });
    resolve('Added');
  });
}

export function addQuiz(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('authUser'));
      var docRef = firebase.firestore().collection('training_module').doc();
      docRef
        .set({
          auto_id: docRef.id,
          ref_manager_id: user?.uid,
          is_active: '1',
          questions: event.questions,
          question_raw: event.question_raw,
          training_module_name: event.training_module_name,
          video_url: event.video_url,
          quiz_title: event.quiz_title,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function updateQuiz(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('training_module')
        .doc(event.id);
      docRef
        .update({
          questions: event.questions,
          question_raw: event.question_raw,
          training_module_name: event.training_module_name,
          video_url: event.video_url,
          quiz_title: event.quiz_title,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}
export function deleteQuiz(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase
        .firestore()
        .collection('training_module')
        .doc(event);
      // Set the "capital" field of the city 'DC'
      docRef
        .update({
          is_active: false,
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
export function changeStatusStart(event, id) {
  return new Promise((resolve, reject) => {
    console.log(event, id);
    firebase
      .firestore()
      .collection('training_module')
      .doc(id)
      .update({
        started: true,
      })
      .then(() => {
        resolve('updated');
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// export function getScoreCard (quiz_id) {
//   console.log(quiz_id)
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       let postsArray = [];
//       console.log(quiz_id)
//       firebase.firestore().collection("scorecard")
//         .where("ref_quiz_id", "==", quiz_id)
//         .where("created_by", "==", firebase.auth().currentUser.uid)
//         .get().then(function (posts) {
//             posts.forEach((doc) => {
//               var data = doc.data();
//               data.id = doc.id;
//               postsArray.push(data);
//               console.log(postsArray)
//             });
//             console.log(posts.size === postsArray.length)
//             if (posts.size === postsArray.length) {
//               resolve(postsArray);
//               console.log(postsArray)
//               dispatch({type: GET_SCORE, payload: postsArray});
//             }
//           })
//         .catch((err) => {
//           console.log(err);
//           reject(err.message)
//         });
//     })
//   }
// };

export function getScoreCard(quiz_id) {
  console.log(quiz_id);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let postsArray = [];
      console.log(quiz_id);
      firebase
        .firestore()
        .collection('scorecard')
        .where('ref_quiz_id', '==', quiz_id)
        .where('created_by', '==', firebase.auth().currentUser.uid)
        .get()
        .then(function (posts) {
          posts.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            data.answerScore =
              doc.data().score + ' / ' + doc.data().questions_length;
            // postsArray.push(data);
            // console.log(postsArray)
            var prodref = firebase
              .firestore()
              .collection('training_module')
              // .where("auto_id", "==", quiz_id)
              .doc(doc.data()?.ref_quiz_id);
            prodref.get().then((squerySnapshot) => {
              console.log(squerySnapshot.exists);
              // data.prodinfo = squerySnapshot.exists ? squerySnapshot.data() : {};
              data.quiztitle = squerySnapshot.data().quiz_title;
              console.log(squerySnapshot.data());
              postsArray.push(data);
              console.log('score card---------', postsArray);

              console.log(posts.size === postsArray.length);
              if (posts.size === postsArray.length) {
                resolve(postsArray);
                console.log(postsArray);
                dispatch({type: GET_SCORE, payload: postsArray});
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
          reject(err.message);
        });
    });
  };
}

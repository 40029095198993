import React from 'react';
import {RoutePermittedRole} from '../../shared/constants/AppEnums';

const Page1 = React.lazy(() => import('./Page1'));
const Page2 = React.lazy(() => import('./Page2'));
const Plan = React.lazy(() => import('./Plans'));
const Blog = React.lazy(() => import('./Blog'));
const ViewTrainingModule =  React.lazy(() =>
import('./Training Modules/ViewTrainingModule'),
);
const Products = React.lazy(() => import('./product'));
const ProductDetail = React.lazy(() => import('./ProductDetail'));
const ViewBlog = React.lazy(() => import('./Blog/ViewBlog'));
const TrainingModule = React.lazy(() => import('./Training Modules'));
const Meeting = React.lazy(() => import('./Meetings'));
const ViewMeeting = React.lazy(() => import('./Meetings/ViewMeeting'));
const ViewFeedback = React.lazy(() => import('./Page2/ViewFeedback'));
const Scorecard = React.lazy(() =>
  import('./Training Modules/View Training Module/index'),
);
const Calendar = React.lazy(() => import('./Calendar'));
const DashBoard = React.lazy(() => import('./DashBoard'));
const Chat = React.lazy(() => import('./Chat'));

// const viewTrainingModule = React.lazy(() =>
//   import('./Training Modules/viewTrainingModule'),
// );
export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/DashBoard',
    element: <DashBoard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Chat',
    element: <Chat />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/page-2',
    element: <Page2 />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/page-1',
    element: <Page1 />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/viewTrainingModule',
    element: <ViewTrainingModule />,
  },  
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/blog',
    element: <Blog />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/ViewBlog',
    element: <ViewBlog />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/TrainingModule',
    element: <TrainingModule />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Scorecard',
    element: <Scorecard />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: ['/sample/product_detail/', '/sample/product_detail/:id'],
    element: <ProductDetail />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/products',
    element: <Products />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/plan',
    element: <Plan />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Meetings',
    element: <Meeting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/ViewMeeting',
    element: <ViewMeeting />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/ViewFeedback',
    element: <ViewFeedback />,
  },  
  {
    permittedRole: RoutePermittedRole.user,
    path: '/sample/Calendar',
    element: <Calendar />,
  },     
];

import {
  // GET_BRANDS,
  // GET_PLAN,
  FETCH_SUCCESS,
  GET_BLOGS,
} from '../../shared/constants/ActionTypes';
// import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const fetchBlogs = (user) => {
  console.log(user);
  return (dispatch) => {
    var docRef = firebase
      .firestore()
      .collection('channel_partner_basic_info')
      .doc(user?.uid);
    // console.log(docRef)
    const bloglist = [];
    // let querysize
    docRef.get().then((querySnapshot) => {
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('created_by', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        console.log(manager_id);
        // var index = manager_id.findIndex(
        //   (created) => created.is_active === true,
        //   console.log(index),
        // );
        if (bloglist.length === 0) {
          dispatch({type: GET_BLOGS, payload: bloglist});
          // dispatch({type: GET_EVENTS, payload: meetings});
        }
        var blogref = firebase
          .firestore()
          .collection('blogs')
          .where('is_active', '==', '1')
          .where('ref_manager_id', '==', manager_id);
        // .orderBy('created_at', 'desc');
        blogref.get().then((squerySnapshot) => {
          squerySnapshot.forEach((doc) => {
            console.log(squerySnapshot.empty);
            var data = doc.data();
            console.log(data);
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data(), squerySnapshot);
            // var data = doc.data();
            bloglist.push(data);
            console.log(bloglist);
            var arr_img = [];
            bloglist.map((img) => {
              var obj_arr = {
                auto_id: img.auto_id,
                ref_manager_id: img.ref_manager_id,
                blog_title: img.blog_title,
                is_active: img.is_active,
                is_read: img.is_read,
                blog: img.blog,
                blog_file: img.blog_file,
              };
              arr_img.push(obj_arr);
            });
            console.log(arr_img);
            console.log(bloglist.length);
            console.log(bloglist.length === arr_img.length);
            if (bloglist.length === arr_img.length) {
              console.log(bloglist);
              dispatch({type: GET_BLOGS, payload: arr_img});
            }
          });
        });
      });
    });
  };
};
export const fetchNotifyBlogs = (user) => {
  console.log(user);
  return new Promise((resolve) => {
    var docRef = firebase
      .firestore()
      .collection('channel_partner_basic_info')
      .doc(user?.uid);
    const bloglist = [];
    docRef.get().then((querySnapshot) => {
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('created_by', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        console.log(manager_id);
        var blogref = firebase
          .firestore()
          .collection('blogs')
          .where('is_active', '==', '1')
          .where('ref_manager_id', '==', manager_id)
          .where('is_read', '==', false);
        blogref.get().then((squerySnapshot) => {
          squerySnapshot.forEach((doc) => {
            console.log(squerySnapshot.empty);
            var data = doc.data();
            console.log(data);
            data.id = doc.data().auto_id;
            data.name = doc.data().blog_title;
            (data.image = doc.data().blog_title.charAt(0).toUpperCase()),
              (data.message = 'Added New Blogs'),
              // doc.data() is never undefined for query doc snapshots
              console.log(doc.id, ' => ', doc.data(), squerySnapshot);
            bloglist.push(data);
            console.log('--------blog-list-----', bloglist);
            console.log(bloglist.length === squerySnapshot.size);
            if (bloglist.length === squerySnapshot.size) {
              console.log('--------blog-list-----', bloglist);
              resolve(bloglist);
            }
          });
        });
      });
    });
  });
};

export function AddBlog(event, title) {
  return (dispatch) => {
    console.log(event, title);
    return new Promise((resolve, reject) => {
      let user = JSON.parse(localStorage.getItem('authUser'));
      var docRef = firebase.firestore().collection('blogs').doc();
      docRef
        .set({
          auto_id: docRef.id,
          ref_manager_id: user?.uid,
          is_active: '1',
          blog_title: title.blog_title,
          blog: event,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}
export function updateblog(event, title) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('blogs').doc(event.id);
      docRef
        .update({
          blog_title: title.blog_title ?? '',
          blog: event ?? '',
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}
export function onDeleteBlog(id, value) {
  return () => {
    return new Promise((resolve, reject) => {
      console.log(id, value);

      var docRef = firebase.firestore().collection('blogs').doc(id, value);
      // Set the "capital" field of the city 'DC'
      docRef
        .delete({
          ref_status_id: [],
        })
        .then(() => {
          console.log('Document successfully Deleted!');
          resolve('Document successfully Deleted!');
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
export function changeStatusRead(event, id) {
  return new Promise((resolve, reject) => {
    console.log(event, id);
    firebase
      .firestore()
      .collection('blogs')
      .doc(id)
      .update({
        is_read: true,
      })
      .then(() => {
        resolve('updated');
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function changeNotifyStatus(event) {
  console.log(event);
  return new Promise((resolve, reject) => {
    console.log(event);
    firebase
      .firestore()
      .collection('blogs')
      .doc(event.auto_id)
      .update({
        is_notify: true,
      })
      .then(() => {
        resolve('updated');
      })
      .catch((err) => {
        reject(err);
      });
  });
}
// export function changeStatusRead(event) {
//   return (dispatch) => {
//     return new Promise((resolve, reject) => {
//       console.log(event);

//       var docRef = firebase
//         .firestore()
//         .collection('blogs')
//         .doc(event);
//       // Set the "capital" field of the city 'DC'
//       docRef
//         .update({
//           is_read: false,
//         })
//         .then(() => {
//           console.log('Document successfully updated!');
//           resolve('Document successfully updated!');
//           dispatch({type: FETCH_SUCCESS});
//         })
//         .catch((error) => {
//           console.error('Error updating document: ', error);
//           reject(error);
//         });
//     });
//   };
// }

import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  FETCH_SUCCESS,
  GET_PARTNERS,
  GET_TREE_CAT,
} from '../../shared/constants/ActionTypes';
// import {GET_TREE_CAT} from '../../shared/constants/ActionTypes'
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const fetchPartners = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('created_by', 'array-contains-any', [user?.uid]);
    // .orderBy('created_at', 'desc');
    const partners = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        data.auth_id = doc.id;
        data.key = doc.id;
        partners.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_PARTNERS, payload: partners});
    });
  };
};

export const GetPartners = (user) => {
  console.log(user);
  return new Promise((resolve) => {
    var docRef = db
      .collection('channel_partner_basic_info')
      .where('auto_id', '==', user.uid);
    const partners = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data.key = doc.id;
        partners.push(data);
        resolve(data);
      });
    });
  });
};
export const GetManagers = (cId) => {
  console.log(cId);
  return new Promise((resolve) => {
    var Idlist = cId;
    console.log(Idlist);
    const managers = [];
    Idlist.forEach((id) => {
      console.log(id);
      var docRef = db.collection('brand_manager').doc(id);
      docRef.get().then((doc) => {
        console.log(doc.data());
        var data = doc.data();
        console.log(data);
        data.username =
          doc.data().brand_manager_firstname +
          ' ' +
          doc.data().brand_manager_lastname;
        data.key = doc.id;
        managers.push(data);
        if (Idlist.length === managers.length) {
          console.log(managers);
          resolve(managers);
        }
      });
    });
  });
};

export const getbrands = (user) => {
  return (dispatch) => {
    var docRef = db.collection('brands').where('created_by', '==', user?.uid);
    // .orderBy('created_at', 'desc');
    const brandslist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.key = doc.id;
        brandslist.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_BRANDS, payload: brandslist});
    });
  };
};

export const getregions = (user) => {
  return (dispatch) => {
    var docRef = db
      .collection('brand_region')
      .where('is_active', '==', '1')
      .where('created_by', '==', user?.uid);
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();

        data.key = doc.id;

        adminslist.push(data);
      });
      dispatch({type: GET_BRANDS_ADMIN, payload: adminslist});
    });
  };
};

export function addbrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      var docRef = firebase.firestore().collection('brands').doc();
      var storageRef = firebase.storage().ref();
      if (event.brand_logo) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        console.log(uploadTask);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.set({
                auto_id: docRef.id,
                // brand_code: "",
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_email: event.brand_email,
                brand_contactno: event.brand_contactno,
                brand_address: event.brand_address,
                is_deleted: false,
                is_active: true,
                created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                created_by: firebase.auth().currentUser.uid,
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('added');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.set({
          auto_id: docRef.id,
          // brand_code: "",
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_logo: event?.brand_logo ?? '',
          brand_email: event.brand_email,
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          is_deleted: false,
          is_active: true,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('added');
      }
    });
  };
}

export function createregions(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('brand_region').doc();
      docRef
        .set({
          auto_id: docRef.id,
          brand_region_code: '',
          brand_region_name: event.brand_region_name,
          brand_region_address: event.brand_region_address,
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('Document successfully written!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function addPlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase.firestore().collection('md_brand_plan').doc();
      docRef
        .set({
          id: docRef.id,
          auto_id: docRef.id,
          ref_plan_id: docRef.id,
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          tagColor: '#11C15B',
          priceColor: '#0A8FDC',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          plan_last_login: '',
          max_channel_count: event.MaximumChannelcount ?? '',
          plan_subscription_expiry_date:
            event.plan_subscription_expiry_date ?? '',
          is_active: '1',
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
          pricingList: [
            {
              id: 1,
              title: 'All features from previous plan',
            },
            {
              id: 2,
              title: 'Memberships and bundles',
            },
            {
              id: 3,
              title: 'Advanced quizzes',
            },
            {
              id: 4,
              title: 'Private & hidden courses',
            },
            {
              id: 5,
              title: '2 Site admin accounts',
            },
            {
              id: 6,
              title: '5 Course admins/authors',
            },
          ],
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export function UpdatePlan(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('md_brand_plan')
        .doc(event.id);
      docRef
        .update({
          name: event.PlanName ?? '',
          tag: event.plantype ?? '',
          title: event.plantype ?? '',
          price: event.PlanPrice ?? 0,
          max_channel_count: event.MaximumChannelcount ?? '',
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

export const getplan = () => {
  return (dispatch) => {
    var docRef = db.collection('md_brand_plan').orderBy('created_at', 'desc');
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        adminslist.push(data);
      });
      dispatch({type: GET_PLAN, payload: adminslist});
    });
  };
};

export const updatebrandadmin = (event, admin) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = db.collection('brand_admin').doc(admin);
      docRef
        .update({
          brand_admin_email: event.Email ?? '',
          brand_admin_firstname: event.FirstName ?? '',
          brand_admin_lastname: event.LastName ?? '',
          brand_admin_mobilenumber: event.Mobile ?? '',
          ref_brand_code: event?.Brand ?? '',
          ref_status_id: event.ref_status_id,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        })
        .then((res) => {
          console.log('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
          resolve(res);
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
};

export function updatebrand(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      console.log(event);
      var docRef = firebase.firestore().collection('brands').doc(event.id);
      var storageRef = firebase.storage().ref();
      if (event.isnewfile) {
        var uploadTask = storageRef
          .child('/brands/' + event.brand_logo.file.name + new Date().getTime())
          .put(event.brand_logo.file, metadata);
        // console.log(uploadTask)
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              event.brand_logo = downloadURL;
              docRef.update({
                brand_name: event.brand_name,
                brand_desc: event.brand_desc,
                brand_logo: event.brand_logo,
                brand_email: event.brand_email,
                brand_contactno: event.brand_contactno,
                brand_address: event.brand_address,
                updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                updated_by: firebase.auth().currentUser.uid,
              });
              resolve('updated');
              dispatch({type: FETCH_SUCCESS});
            });
          },
        );
      } else {
        docRef.update({
          brand_name: event.brand_name,
          brand_desc: event.brand_desc,
          brand_email: event.brand_email,
          brand_contactno: event.brand_contactno,
          brand_address: event.brand_address,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_by: firebase.auth().currentUser.uid,
        });
        resolve('updated');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

export function onDeleteBrandAdmin(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);

      var docRef = firebase.firestore().collection('brand_admin').doc(event);
      // Set the "capital" field of the city 'DC'
      docRef
        .update({
          ref_status_id: 0,
        })
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}
export const GetTreeCategories = (user) => {
  console.log(user);
  return (dispatch) => {
    // let user = JSON.parse(localStorage.getItem('authUser'));
    var docRef = db.collection('product_categories');
    // .where('created_by', '==', user?.uid)
    const categories = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        // data.auto_id = doc.id;
        // data.auth_id = doc.id;
        data.key = doc.id;
        data.title = doc.data().category_name;
        categories.push(data);
        console.log(categories);
        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_TREE_CAT, payload: categories});
    });
  };
};
// export const GetTreeCategories = ( user) => {
//   console.log( user);
//   return (dispatch) => {
//       var docRef = db.collection('channel_partner_basic_info')
//       .doc(user?.uid)
//     console.log(docRef)
//     const adminslist = []
//       docRef.get().then((querySnapshot) => {

//           console.log( querySnapshot.data())
//           let cp = querySnapshot.data()
//           cp.id = querySnapshot.id
//           console.log(cp)
//           let productsinfo = []
//           console.log(cp.ref_product_id.length)
//           if (cp.ref_product_id.length === 0) {
//           dispatch({type: GET_TREE_CAT, payload: productsinfo});
//          } else {
//           cp.ref_product_id.forEach(id => {
//             var prodref = db.collection("products").doc(id)
//             prodref.get().then(squerySnapshot => {
//               console.log(squerySnapshot.exists)
//               squerySnapshot.exists
//                 ? productsinfo.push(squerySnapshot.data())
//                 : null
//               cp.productsinfo = productsinfo
//               adminslist.push(cp)
//               console.log(productsinfo)
//               productsinfo.map((e) => {
//                 var catid = {
//                   category_id : e.ref_category_id
//                 }
//                 console.log(catid)
//               })
//               console.log(cp.ref_product_id.length, cp.productsinfo.length)
//               if (cp.ref_product_id.length === cp.productsinfo.length) {
//                 console.log(adminslist)
//                 // resolve(adminslist)
//                 dispatch({type: GET_TREE_CAT, payload: productsinfo});
//               }
//             })
//           })
//         }

//       })
//   };
// };

// export const GetTreeCategories = (user) => {
//   return (dispatch) => {
//     var docRef = db.collection('channel_partner_basic_info')
//         .doc(user?.uid)
//       console.log(docRef)
//       const adminslist = []
//         docRef.get().then((querySnapshot) => {
//             console.log( querySnapshot.data())
//             let cp = querySnapshot.data()
//             cp.id = querySnapshot.id

//             // let categories = []
//             console.log(cp.created_by.length)
//             if (cp.created_by.length === 0) {
//               dispatch({type: GET_TREE_CAT, payload: adminslist});
//             } else {
//             cp.created_by.forEach(id => {
//               console.log(id)
//               var prodref = db.collection("product_categories").where("created_by", "==", id)
//               // .doc(id)
//               prodref.get().then(squerySnapshot => {
//                 console.log(squerySnapshot.size)
//                 squerySnapshot.forEach((doc) => {
//                   // if (doc.data().ref_brand_code == false) {
//                   console.log(doc.id, ' => ', doc.data(), querySnapshot);
//                   var data = doc.data();
//                   // data.auto_id = doc.id;
//                   // data.auth_id = doc.id;
//                   data.key = doc.id;
//                   data.title = doc.data().category_name;
//                   adminslist.push(data);
//                   console.log(adminslist)
//                   // doc.data() is never undefined for query doc snapshots
//                 });
//                  dispatch({type: GET_TREE_CAT, payload: adminslist});
//               })
//             })
//           }

//         })
//   };
// };
export function changePassword(event) {
  console.log(event);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var docRef = firebase
        .firestore()
        .collection('channel_partner_basic_info')
        .doc(event.id);
      docRef
        .update({
          channel_partner_password: event.channel_partner_password,
        })
        .then(() => {
          console.log('Document successfully written!');
          resolve('added');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
          reject(error);
        });
    });
  };
}

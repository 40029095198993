import {
  ADD_CART_ITEM,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CUSTOMERS,
  GET_ECOMMERCE_LIST,
  GET_RECENT_ORDER,
  REMOVE_CART_ITEM,
  SET_FILTER_DATA,
  SET_PRODUCT_DATA,
  SET_PRODUCT_VIEW_TYPE,
  UPDATE_CART_ITEM,
  // GET_TREE_CAT,
} from '../../shared/constants/ActionTypes';
// import {GET_TREE_CAT} from '../../shared/constants/ActionTypes'
import Api from '../../@crema/services/ApiConfig';
import firebase from 'firebase';
import {db} from '../../@crema/services/auth/firebase/firebase';

export const onGetEcommerceData = (user) => {
  // return new Promise((resolve) => {
  console.log(user);
  return (dispatch) => {
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    const adminslist = [];
    // let productdoc = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      let cp = querySnapshot.data();
      cp.id = querySnapshot.id;
      console.log(cp);
      let productsinfo = [];
      console.log(cp.ref_product_id.length);
      if (cp.ref_product_id.length === 0) {
        dispatch({type: GET_ECOMMERCE_LIST, payload: productsinfo});
      } else {
        cp.ref_product_id.forEach((id) => {
          var prodref = db.collection('products').doc(id);
          prodref.get().then((squerySnapshot) => {
            // console.log(squerySnapshot.exists)
            squerySnapshot.exists
              ? productsinfo.push(squerySnapshot.data())
              : null;
            cp.productsinfo = productsinfo;
            adminslist.push(cp);
            console.log(productsinfo);

            console.log(cp.ref_product_id.length, cp.productsinfo.length);
            if (cp.ref_product_id.length === cp.productsinfo.length) {
              console.log(adminslist);
              console.log(productsinfo);
              dispatch({type: GET_ECOMMERCE_LIST, payload: productsinfo});
            }
            // dispatch({type: GET_TREE_CAT, payload: productdoc});
          });
        });
      }
    });
  };
  // })
};
export const onGetEcommerceData1 = (user) => {
  return new Promise((resolve, reject) => {
    console.log(user);
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    const adminslist = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      let cp = querySnapshot.data();
      cp.id = querySnapshot.id;
      console.log(cp);
      let productsinfo = [];
      console.log(cp.ref_product_id);
      console.log(cp.ref_product_id.length);
      if (cp.ref_product_id.length === 0) {
        reject(productsinfo);
      } else {
        cp.ref_product_id.forEach((id) => {
          var prodref = db.collection('products').doc(id);
          prodref.get().then((squerySnapshot) => {
            // console.log(squerySnapshot.exists)
            squerySnapshot.exists
              ? productsinfo.push(squerySnapshot.data())
              : null;
            cp.productsinfo = productsinfo;
            adminslist.push(cp);
            console.log(productsinfo);
            console.log(cp.ref_product_id.length, cp.productsinfo.length);
            if (cp.ref_product_id.length === cp.productsinfo.length) {
              console.log(productsinfo);
              resolve(productsinfo);
            }
          });
        });
      }
    });
  });
};

export const getselectedCategory = (catbucket) => {
  console.log('category-------------------------', catbucket);
  // console.log("sub category-------------------------",subcatbucket)
  return new Promise((resolve) => {
    var catlist = catbucket;
    console.log(catlist);
    let productdoc = [];
    var subcatlist = [];
    var subcount = 0;

    catlist.forEach((id) => {
      console.log(id);
      var catref = firebase
        .firestore()
        .collection('product_categories')
        .doc(id.ref_category_id);

      catref.get().then((doc) => {
        console.log(doc.id, ' => ', doc.data());
        var data = doc.data();
        console.log(data);
        var subRer = db
          .collection('product_subcategories')
          .where('ref_category_id', '==', id.ref_category_id);
        subRer.get().then((subCatres) => {
          console.log(subCatres.size);
          // var narr = [];
          subCatres.forEach((note) => {
            subcount++;
            console.log(subcount);
            console.log(note.data());
            var subcat_obj = {
              title: note.data().subcategory_name,
              key: note.id,
              is_cat: false,
            };
            subcatlist.push(subcat_obj);
            // narr.push(note.data());
          });
          console.log(subcatlist);
          // data.subcat = narr;

          console.log(subCatres.size);

          if (subCatres.size === subcount) {
            var obj_arr = {
              title: doc.data().category_name,
              key: doc.id,
              children: subcatlist,
              is_cat: true,
            };
            subcount = 0;
            subcatlist = [];
            console.log(obj_arr);
            productdoc.push(obj_arr);
            console.log(productdoc);
          }
          // productdoc.push(data);
          console.log(productdoc);
          const map = new Map(productdoc.map((obj) => [obj.key, obj]));
          //2. go over the second array
          productdoc.forEach((obj) => {
            if (
              !map.has(obj.key) || //not a duplicate
              obj.title
            ) {
              //is not hidden
              //insert
              map.set(obj.key, obj);
            }
          });
          //3. convert back into array
          const merged = [...map.values()];
          console.log(merged);
          console.log(catlist.length);
          console.log(productdoc.length);
          if (catlist.length === productdoc.length) {
            resolve(merged);
          }
        });
      });
    });
  });
};
export const getselectedProduct = (user, subCat_id) => {
  // return new Promise((resolve) => {
  console.log(user, subCat_id);
  return (dispatch) => {
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    const adminslist = [];
    let filtProduct = [];
    // let productdoc = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      let cp = querySnapshot.data();
      cp.id = querySnapshot.id;
      console.log(cp);
      let productsinfo = [];
      console.log(cp.ref_product_id.length);
      if (cp.ref_product_id.length === 0) {
        dispatch({type: GET_ECOMMERCE_LIST, payload: productsinfo});
      } else {
        cp.ref_product_id.forEach((id) => {
          var prodref = db.collection('products').doc(id);
          // .where("fk_subcategory", "==", subCat_id)
          prodref.get().then((squerySnapshot) => {
            console.log(squerySnapshot.data());
            squerySnapshot.exists
              ? productsinfo.push(squerySnapshot.data())
              : null;
            cp.productsinfo = productsinfo;
            adminslist.push(cp);
            console.log(productsinfo);

            console.log(cp.ref_product_id.length, cp.productsinfo.length);
            if (cp.ref_product_id.length === cp.productsinfo.length) {
              productsinfo
                .filter((subcat) => subcat.ref_category_id === subCat_id)
                .map(
                  (filteredProduct) => (
                    // {filteredProduct},
                    console.log(filteredProduct),
                    filtProduct.push(filteredProduct)
                  ),
                );
              console.log(filtProduct);
              dispatch({type: GET_ECOMMERCE_LIST, payload: filtProduct});
              // console.log(productsinfo)
            }
            // dispatch({type: GET_TREE_CAT, payload: productdoc});
          });
        });
      }
    });
  };
  // })
};
export const getselectedProduct1 = (categoryId) => {
  console.log(categoryId);
  // console.log(filcatId)
  // var catlist = filcatId;
  return (dispatch) => {
    // filcatId.forEach(id => {
    //   console.log(id)
    // })
    var docRef = firebase
      .firestore()
      .collection('products')
      .where('ref_category_id', '==', categoryId);
    // .where("fk_subcategory", "==", filcatId)
    // .where("created_by", "==", firebase.auth().currentUser.uid)

    const productdoc = [];
    var intial = 0;
    docRef.get().then((querySnapshot) => {
      // if (productdoc.length === 0) {
      dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
      // } else {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data.key = doc.id;
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var arr_img = [];
        data.pd_image.map((img) => {
          intial++;
          var obj_arr = {
            uid: intial,
            edit: true,
            url: img.img_url,
          };
          arr_img.push(obj_arr);
        });
        data.mod_imgs = arr_img;
        productdoc.push(data);
        console.log(productdoc);
        // resolve(meetDoc)
        dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
      });
    });
  };
};
export const getselectedCategoryProduct1 = (subCat_id) => {
  console.log(subCat_id);
  // console.log("subcat ----======",subcatId)

  return (dispatch) => {
    var docRef = firebase
      .firestore()
      .collection('products')
      .where('fk_subcategory', '==', subCat_id);
    // .where("created_by", "==", firebase.auth().currentUser.uid)

    const productdoc = [];
    var intial = 0;
    docRef.get().then((querySnapshot) => {
      // if (productdoc.length === 0) {
      dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
      // } else {
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        data.key = doc.id;
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var arr_img = [];
        data.pd_image.map((img) => {
          intial++;
          var obj_arr = {
            uid: intial,
            edit: true,
            url: img.img_url,
          };
          arr_img.push(obj_arr);
        });
        data.mod_imgs = arr_img;
        productdoc.push(data);
        console.log(productdoc);
        // resolve(meetDoc)
        dispatch({type: GET_ECOMMERCE_LIST, payload: productdoc});
      });
    });
  };
};
export const getselectedCategoryProduct = (user, subCat_id) => {
  // return new Promise((resolve) => {
  console.log(user, subCat_id);
  return (dispatch) => {
    var docRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(docRef);
    const adminslist = [];
    let filtProduct = [];
    // let productdoc = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      let cp = querySnapshot.data();
      cp.id = querySnapshot.id;
      console.log(cp);
      let productsinfo = [];
      console.log(cp.ref_product_id.length);
      if (cp.ref_product_id.length === 0) {
        dispatch({type: GET_ECOMMERCE_LIST, payload: productsinfo});
      } else {
        cp.ref_product_id.forEach((id) => {
          var prodref = db.collection('products').doc(id);
          // .where("fk_subcategory", "==", subCat_id)
          prodref.get().then((squerySnapshot) => {
            console.log(squerySnapshot.data());
            squerySnapshot.exists
              ? productsinfo.push(squerySnapshot.data())
              : null;
            cp.productsinfo = productsinfo;
            adminslist.push(cp);
            console.log(productsinfo);

            console.log(cp.ref_product_id.length, cp.productsinfo.length);
            if (cp.ref_product_id.length === cp.productsinfo.length) {
              productsinfo
                .filter((subcat) => subcat.fk_subcategory === subCat_id)
                .map(
                  (filteredProduct) => (
                    // {filteredProduct},
                    console.log(filteredProduct),
                    filtProduct.push(filteredProduct)
                  ),
                );
              console.log(filtProduct);
              dispatch({type: GET_ECOMMERCE_LIST, payload: filtProduct});
              // console.log(productsinfo)
            }
            // dispatch({type: GET_TREE_CAT, payload: productdoc});
          });
        });
      }
    });
  };
  // })
};
export const getProductDetail = (id) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/get', {
      params: {id: id},
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: SET_PRODUCT_DATA, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getRecentOrders = (search, page) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/orders', {
      params: {search, page},
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_RECENT_ORDER, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
export const getCustomers = (search, page) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/ecommerce/customers', {
      params: {search, page},
    })
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_CUSTOMERS, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const getCartItems = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    //   Api.get('/api/cart/get')
    //     .then((data) => {
    //       if (data.status === 200) {
    dispatch({type: FETCH_SUCCESS});
    //         dispatch({type: SET_CART_ITEMS, payload: data.data});
    //       } else {
    //         dispatch({
    //           type: FETCH_ERROR,
    //           payload: 'Something went wrong, Please try again!',
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       dispatch({type: FETCH_ERROR, payload: error.message});
    //     });
  };
};

export const setViewType = (viewType) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_VIEW_TYPE, payload: viewType});
  };
};

export const removeCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: REMOVE_CART_ITEM, payload: data});
  };
};

export const updateCartItem = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_CART_ITEM, payload: data});
  };
};

export const addItemToCart = (data) => {
  return (dispatch) => {
    dispatch({type: ADD_CART_ITEM, payload: data});
  };
};

export const setCurrentProduct = (product) => {
  return (dispatch) => {
    dispatch({type: SET_PRODUCT_DATA, payload: product});
  };
};

export const setFilters = (filters) => {
  return (dispatch) => {
    dispatch({type: SET_FILTER_DATA, payload: filters});
  };
};

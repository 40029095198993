//import {GET_PRODUCTS} from '../../shared/constants/ActionTypes';
import {
  FETCH_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCT_FEEDDBACK,
} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export const fetchProducts = () => {
  return (dispatch) => {
    var docRef = db.collection('products');
    // .orderBy('created_at', 'desc');
    const partners = [];
    docRef.get().then((querySnapshot) => {
      console.log(querySnapshot);
      querySnapshot.forEach((doc) => {
        // if (doc.data().ref_brand_code == false) {
        console.log(doc.id, ' => ', doc.data(), querySnapshot);
        var data = doc.data();
        data.auto_id = doc.id;
        // data.auth_id = doc.id
        data.key = doc.id;
        partners.push(data);

        // doc.data() is never undefined for query doc snapshots
      });
      dispatch({type: GET_PRODUCTS, payload: partners});
    });
  };
};

export function updateProduct(event, deliveryDate, fileList) {
  console.log('image');
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var metadata = {
        contentType: 'image/jpeg',
      };
      var imagearray = [];
      var docRef = firebase.firestore().collection('product_feedback').doc();
      var storageRef = firebase.storage().ref();
      console.log(fileList);
      if (event.isnewfile) {
        fileList.forEach((f) => {
          console.log('Win');
          var uploadTask = storageRef
            .child('/productfeedback/' + f.name + new Date().getTime())
            .put(f, metadata);
          console.log(uploadTask);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                var obj_img = {
                  img_url: downloadURL,
                };
                imagearray.push(obj_img);
                console.log(obj_img);
                if (imagearray.length === fileList.length) {
                  console.log(imagearray);
                  event.win_po_image = imagearray;
                  docRef.set({
                    auto_id: docRef.id,
                    ref_product_id: event.ref_product_id,
                    ref_manager_id: event.ref_manager_id,
                    ref_feedback_id: parseInt(event.ref_feedback_id),
                    product_feedback: event.meeting_feedback,
                    win_delivery_date: deliveryDate,
                    win_extra_info: event.win_extra_info ?? '',
                    win_po_image: event.win_po_image,
                    // win_po_image : imagearray,
                    contact_name: event.contact_name,
                    contact_number: event.contact_number,
                    contact_email: event.contact_email.toLowerCase(),
                    address: event.address,
                    ref_status_id: 0,
                    created_at: firebase.firestore.Timestamp.fromDate(
                      new Date(),
                    ),
                    updated_at: firebase.firestore.Timestamp.fromDate(
                      new Date(),
                    ),
                    created_by: firebase.auth().currentUser.uid,
                    updated_by: firebase.auth().currentUser.uid,
                  });
                  resolve('added');
                }
              });
            },
          );
        });
      } else {
        console.log('Lost', event);
        let post = {
          auto_id: docRef.id,
          // win_po_image : imagearray,
          ref_manager_id: event.ref_manager_id,
          ref_product_id: event.ref_product_id,
          ref_feedback_id: parseInt(event.ref_feedback_id),
          product_feedback: event.meeting_feedback,
          contact_name: event.contact_name,
          contact_number: event.contact_number,
          contact_email: event.contact_email.toLowerCase(),
          address: event.address,
          ref_status_id: 0,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        };
        if (parseInt(event.ref_feedback_id) === 2) {
          post.reason_for_lost = event.reason_for_lost ?? '';
        }
        docRef.set(post);
        resolve('added');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}

export function onDeleteProduct(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);
      var docRef = firebase.firestore().collection('products').doc(event);
      docRef
        .delete()
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}

export function unmapProducts(values, arr) {
  // console.log(values, partners)
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('channel_partner_basic_info')
      .doc(values.channel_partner);
    docRef
      .update({
        ref_product_id: arr,
      })
      .then(() => {
        resolve('Updated');
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const GetChats = (user) => {
  console.log('get chats', user);
  return new Promise((resolve) => {
    console.log(user);
    // return (dispatch) => {

    var myHeaders = new Headers();
    myHeaders.append('Project-ID', '90dd542e-6747-497a-9221-7f218164dbbb');
    myHeaders.append('User-Name', user.displayName);
    myHeaders.append('User-Secret', user.email);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch('https://api.chatengine.io/chats/', requestOptions)
      .then((response) => response.text())
      .then((result) =>
        console.log(JSON.parse(result), resolve(addFeedbackToChat({result}))),
      )
      .catch((error) => console.log('error', error));
  });
};
export function addFeedbackToChat({result}) {
  console.log(result);
  console.log(JSON.parse(result));
}
export function addProductFeedback(event, deliveryDate, fileList) {
  console.log('image');
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // db.collection('brand_manager')
      // const chatbaseUrl = 'https://api.chatengine.io';

      // var raw = JSON.stringify({
      //   text: 'Helloworld',
      //   attachment_urls: [''],
      // });
      //   console.log(raw);
      //   var requestOptions = {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'PRIVATE-KEY': 'c957a3f9-87c1-4075-a6f1-6a0ca2ec5d93',
      //     },
      //     body: raw,
      //     redirect: 'follow',
      //   };
      //   console.log(requestOptions);
      //   fetch(chatbaseUrl + '/chats/{{chat_id}}/messages/', requestOptions)
      //     .then(response => response.text())
      // .then(result => console.log(result))
      // .catch(error => console.log('error', error));
      var metadata = {
        contentType: 'image/jpeg',
      };
      var imagearray = [];
      var docRef = firebase.firestore().collection('product_feedback').doc();
      var storageRef = firebase.storage().ref();
      console.log(fileList);
      if (event.win_po_image) {
        fileList.forEach((f) => {
          console.log(f);
          var uploadTask = storageRef
            .child('/productfeedback/' + f.name)
            .put(f, metadata);
          console.log(uploadTask);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            (snapshot) => {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              reject(error);
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;
                case 'storage/canceled':
                  // User canceled the upload
                  break;

                // ...

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                var obj_img = {
                  img_url: downloadURL,
                };
                imagearray.push(obj_img);
                console.log(obj_img);
                if (imagearray.length === fileList.length) {
                  console.log(imagearray);
                  event.win_po_image = imagearray;
                  docRef.set({
                    auto_id: docRef.id,
                    ref_product_id: event.ref_product_id,
                    ref_manager_id: event.ref_manager_id,
                    ref_feedback_id: parseInt(event.ref_feedback_id),
                    product_feedback: event.meeting_feedback,
                    win_delivery_date: deliveryDate,
                    win_extra_info: event.win_extra_info ?? '',
                    win_po_image: event.win_po_image,
                    // win_po_image : imagearray,
                    contact_name: event.contact_name,
                    contact_number: event.contact_number,
                    contact_email: event.contact_email.toLowerCase(),
                    address: event.address,
                    ref_status_id: 0,
                    created_at: firebase.firestore.Timestamp.fromDate(
                      new Date(),
                    ),
                    updated_at: firebase.firestore.Timestamp.fromDate(
                      new Date(),
                    ),
                    created_by: firebase.auth().currentUser.uid,
                    updated_by: firebase.auth().currentUser.uid,
                  });
                  resolve('added');
                }
              });
            },
          );
        });
      } else {
        console.log('Lost', event);
        let post = {
          auto_id: docRef.id,
          // win_po_image : imagearray,
          ref_manager_id: event.ref_manager_id,
          ref_product_id: event.ref_product_id,
          ref_feedback_id: parseInt(event.ref_feedback_id),
          product_feedback: event.meeting_feedback,
          contact_name: event.contact_name,
          contact_number: event.contact_number,
          contact_email: event.contact_email.toLowerCase(),
          address: event.address,
          ref_status_id: 0,
          created_at: firebase.firestore.Timestamp.fromDate(new Date()),
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
          created_by: firebase.auth().currentUser.uid,
          updated_by: firebase.auth().currentUser.uid,
        };
        if (parseInt(event.ref_feedback_id) === 2) {
          post.reason_for_lost = event.reason_for_lost ?? '';
        }
        docRef.set(post);
        resolve('added');
        dispatch({type: FETCH_SUCCESS});
      }
    });
  };
}
export const getProductFeedback = (user) => {
  console.log(user);
  return (dispatch) => {
    return new Promise((resolve) => {
      var docRef = db
        .collection('product_feedback')
        .where('created_by', '==', user?.uid);
      // .where('ref_manager_id', '==', user?.uid)
      // console.log(docRef)
      const prodfb = [];

      docRef.get().then((querySnapshot) => {
        console.log(querySnapshot.size);
        if (querySnapshot.empty) {
          dispatch({type: GET_PRODUCT_FEEDDBACK, payload: prodfb});
        } else {
          querySnapshot.forEach((doc) => {
            console.log(doc.id, ' => ', doc.data(), querySnapshot);
            var feedback = doc.data();
            feedback.id = doc.id;
            // feedback.prodinfo = {}
            console.log(doc.data()?.ref_product_id, 'ProdID');
            var prodref = firebase
              .firestore()
              .collection('products')
              .doc(doc.data()?.ref_product_id);
            prodref.get().then((squerySnapshot) => {
              console.log(squerySnapshot);
              feedback.prodinfo = squerySnapshot.exists
                ? squerySnapshot.data()
                : {};
              feedback.cover = squerySnapshot.data().pd_image[0].img_url;
              feedback.name = squerySnapshot.data().pd_name;

              prodfb.push(feedback);
              console.log(prodfb);
              console.log(prodfb.length, querySnapshot.size);
              if (prodfb.length === querySnapshot.size) {
                console.log(prodfb);
                resolve(prodfb);
                dispatch({type: GET_PRODUCT_FEEDDBACK, payload: prodfb});
              }
            });
          });
        }
      });
    });
  };
};

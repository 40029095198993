import React, {createContext, useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  db,
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from './firebase';
import firebase from 'firebase/app';
import {useDispatch} from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
} from '../../../../shared/constants/ActionTypes';
import {message} from 'antd';

const FirebaseContext = createContext();
const FirebaseActionsContext = createContext();

export const useFirebase = () => useContext(FirebaseContext);

export const useFirebaseActions = () => useContext(FirebaseActionsContext);

const FirebaseAuthProvider = ({children}) => {
  const [firebaseData, setFirebaseData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getAuthUser = auth.onAuthStateChanged(
      (user) => {
        setFirebaseData({
          user: user,
          isLoading: false,
          isAuthenticated: Boolean(user),
        });
      },
      () => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
        });
      },
      (completed) => {
        setFirebaseData({
          user: null,
          isLoading: false,
          isAuthenticated: completed,
        });
      },
    );

    return () => {
      getAuthUser();
    };
  }, [auth]);

  const getProvider = (providerName) => {
    switch (providerName) {
      case 'google': {
        return googleAuthProvider;
      }
      case 'facebook': {
        return facebookAuthProvider;
      }
      case 'twitter': {
        return twitterAuthProvider;
      }
      case 'github': {
        return githubAuthProvider;
      }
      default:
        return googleAuthProvider;
    }
  };

  const signInWithPopup = async (providerName) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await auth.signInWithPopup(getProvider(providerName));
      setFirebaseData({
        user,
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const signInWithEmailAndPassword = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      db.collection('channel_partner_basic_info')
        .where('channel_partner_email', '==', email.toLowerCase())
        .where('ref_role_id', '==', 4)
        .where('is_active', '==', true)
        .get()
        .then((res) => {
          console.log(!res.empty);

          if (!res.empty) {
            auth.signInWithEmailAndPassword(email.toLowerCase(), password).then(
              (user) => {
                console.log(user);
                localStorage.setItem('role', 'admin');
                res.forEach((doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  console.log(doc.id, ' => ', doc.data());
                  auth.currentUser.updateProfile({
                    displayName:
                      doc.data().channel_partner_firstname +
                      ' ' +
                      doc.data().channel_partner_lastname,
                  });
                });
                setFirebaseData({
                  user,
                  isAuthenticated: true,
                  isLoading: false,
                });
                dispatch({type: FETCH_SUCCESS});
              },
              (error) => {
                console.log(error);
                setFirebaseData({
                  ...firebaseData,
                  isAuthenticated: false,
                  isLoading: false,
                });
                dispatch({type: FETCH_ERROR, payload: error.message});
              },
            );
          } else {
            db.collection('channel_partner_basic_info')
              .where('channel_partner_email', '==', email.toLowerCase())
              .where('ref_role_id', '==', 4)
              .where('is_active', '==', true)
              .get()
              .then((res) => {
                console.log(res);
                if (!res.empty) {
                  auth
                    .signInWithEmailAndPassword(email.toLowerCase(), password)
                    .then(
                      (user) => {
                        console.log(user);
                        localStorage.setItem('role', 'manager');
                        setFirebaseData({
                          user,
                          isAuthenticated: true,
                          isLoading: false,
                        });
                        dispatch({type: FETCH_SUCCESS});
                      },
                      (error) => {
                        console.log(error);
                      },
                    );
                } else {
                  //reject(this._handleError('User not found!'));
                  setFirebaseData({
                    ...firebaseData,
                    isAuthenticated: false,
                    isLoading: false,
                  });
                  dispatch({type: FETCH_ERROR, payload: 'User not found!'});
                }
              })
              .catch((error) => {
                console.log(error);
                //reject(this._handleError(error));
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //const {user} = await auth.signInWithEmailAndPassword(email, password);
      //setFirebaseData({user, isAuthenticated: true, isLoading: false});
      //dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const createUserWithEmailAndPassword = async ({name, email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const {user} = await auth.createUserWithEmailAndPassword(email, password);
      await auth.currentUser.sendEmailVerification({
        url: window.location.href,
        handleCodeInApp: true,
      });
      await auth.currentUser.updateProfile({
        displayName: name,
      });
      setFirebaseData({
        user: {...user, displayName: name},
        isAuthenticated: true,
        isLoading: false,
      });
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };
  const updatePassword = async ({password}) => {
    console.log(`password`, password);
    dispatch({type: FETCH_START});
    try {
      const isSuccess = await Promise.all([
        await auth.currentUser
          .updatePassword(password)
          .then(() => {
            console.log(`Password updated`, firebaseData.user);
            var docRef = firebase
              .firestore()
              .collection('channel_partner_basic_info')
              .doc(firebaseData.user?.uid);
            docRef
              .update({
                channel_partner_password: password,
              })
              .then(() => {
                console.log('Document successfully written!');
                // Send new password
                setFirebaseData({
                  user: {...firebaseData.user, password: password},
                  isAuthenticated: true,
                  isLoading: false,
                });
                dispatch({type: FETCH_SUCCESS});
                console.log(`Password updated in authentication`);
                message.success('Password updated Successfully');
              })
              .catch((error) => {
                console.error('Error writing document: ', error);
                setFirebaseData({
                  ...firebaseData,
                  isAuthenticated: false,
                  isLoading: false,
                });
                dispatch({
                  type: FETCH_ERROR,
                  payload: 'Updating password failed',
                });
              });
            // setTimeout(() => {
            //   navigate('/sample/page-1');
            // }, 2000);
            // update your database changes
          })
          .catch((error) => {
            console.log(`Updating password in authentication failed: ${error}`);
            setFirebaseData({
              ...firebaseData,
              isAuthenticated: false,
              isLoading: false,
            });
            dispatch({type: FETCH_ERROR, payload: 'Updating password failed'});
          }),
      ]);
      return isSuccess;
    } catch (e) {
      console.log(`Updating password failed: ${e}`);
      setFirebaseData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({type: FETCH_ERROR, payload: 'Updating password failed'});
    }
  };
  const logout = async () => {
    setFirebaseData({...firebaseData, isLoading: true});
    try {
      await auth.signOut();
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      setFirebaseData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...firebaseData,
      }}>
      <FirebaseActionsContext.Provider
        value={{
          signInWithEmailAndPassword,
          createUserWithEmailAndPassword,
          signInWithPopup,
          logout,
          updatePassword,
        }}>
        {children}
      </FirebaseActionsContext.Provider>
    </FirebaseContext.Provider>
  );
};
export default FirebaseAuthProvider;

FirebaseAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

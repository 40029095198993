import React from 'react';
// import {useNavigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import {List, Avatar} from 'antd';
import './NotificationItem.less';
// import {changeNotifyStatus} from 'redux/actions/blog';
const NotificationItem = (props) => {
  // const navigate = useNavigate();

  const {item} = props;
  console.log(item);
  // const getUserAvatar = () => {
  //   if (item.image) {
  //     return item.image;
  //   }
  // };
  // const handleClick = (e) => {
  //   console.log(e.read);
  // };
  // const handleStatusupdate = (read) => {
  //   console.log(read);
  //   changeNotifyStatus(read)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  return (
    <List.Item
      className='notify-listItem item-hover'
      // onClick={() => {
      //   handleStatusupdate(item);
      //   {
      //     item?.training_module_name
      //       ? navigate('/sample/TrainingModule')
      //       : item?.blog
      //       ? navigate('/sample/blog')
      //       : null;
      //   }
      // }}
    >
      <List.Item.Meta
        avatar={
          <Avatar className='notify-message-avatar ' src={item.image}>
            {item.image}
          </Avatar>
        }
        title={item.name}
        description={item.message}
      />
      {/* {item.is_read === false || item.started === false ? (
        <div>
          <Tag color='#f50'>New</Tag>
        </div>
      ) : null} */}
    </List.Item>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired,
};

import React, {useEffect, useState} from 'react';
// import notification from '../../services/db/notifications/notification';
import {List, Dropdown, Menu, Badge, Button} from 'antd';

import AppScrollbar from '../AppScrollbar';
import IntlMessages from '../../utility/IntlMessages';
import NotificationItem from './NotificationItem';
import './index.style.less';
import {IoIosNotificationsOutline} from 'react-icons/io';
import {useAuthUser} from '../../../@crema/utility/AuthHooks';
// import {fetchNotifyQuiz} from '../../../redux/actions/TrainingModule';
// import {fetchNotifyBlogs} from '../../../redux/actions/blog';
// import {useDispatch} from 'react-redux';
import {db} from '../../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// import {onGetNotifyProduct} from 'redux/actions';
const AppNotifications = () => {
  const {user} = useAuthUser();
  const [BlogNotify, setBlogNotify] = useState('');
  // const [Notify, setNotify] = useState('');
  const [TrainNotify, setTrainNotify] = useState('');
  const [product, setProduct] = useState('');
  const [meeting, setMeeting] = useState('');
  // const [totalData, setTotalData] = useState([])
  useEffect(() => {
    var docRef = firebase
      .firestore()
      .collection('channel_partner_basic_info')
      .doc(user?.uid);
    docRef.get().then((querySnapshot) => {
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('created_by', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        console.log(manager_id);
        db.collection('blogs')
          .where('is_active', '==', '1')
          .where('ref_manager_id', '==', manager_id)
          .where('is_read', '==', false)
          // .where('is_notify', '==', false)
          .onSnapshot((snapshot) => {
            console.log('Onsnapshot triggering');
            // const data = snapshot.docs.map((doc) => doc.data());
            const data = [];
            snapshot.forEach((doc) => {
              console.log('blogs snapshot', doc.data());
              const user = doc.data();
              user.id = doc.id; // Add the document ID to the user object (optional).
              (user.name = doc.data().blog_title),
                (user.image = doc.data().blog_title.charAt(0).toUpperCase()),
                (user.message = 'Blog');
              data.push(user);
            });
            console.log('snapshot data :', data);
            setBlogNotify(data);
            // const filterNotify = data.filter((e) => e.is_notify == false);
            // console.log(filterNotify);
            // setNotify(filterNotify);
          });
      });
    });
    var trainRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(trainRef);
    trainRef.get().then((querySnapshot) => {
      console.log(querySnapshot.data());
      var created_by = querySnapshot
        ?.data()
        .created_by?.filter((created) => created.is_active);
      console.log('querySnapshot', querySnapshot?.data().created_by);
      console.log('created_by training module', created_by);
      created_by.forEach((element) => {
        console.log(element.manager_id);
        var manager_id = element.manager_id;
        db.collection('training_module')
          .where('ref_manager_id', '==', manager_id)
          .where('is_active', '==', '1')
          .where('started', '==', false)
          .onSnapshot((snapshot) => {
            console.log('training snapshot trigering');
            const data = [];
            snapshot.forEach((doc) => {
              console.log('training snapshot', doc.data());
              const user = doc.data();
              user.id = doc.id; // Add the document ID to the user object (optional).
              (user.name = doc.data().training_module_name),
                (user.image = doc
                  .data()
                  .training_module_name.charAt(0)
                  .toUpperCase()),
                (user.message = 'Training');
              data.push(user);
            });
            console.log(data);
            setTrainNotify(data);
          });
      });
    });
    var ProdRef = db.collection('channel_partner_basic_info').doc(user?.uid);
    console.log(ProdRef);
    ProdRef.get().then((querySnapshot) => {
      const adminslist = [];
      console.log('product snapshot', querySnapshot.data());
      let cp = querySnapshot.data();
      cp.id = querySnapshot.id;
      console.log('cp ref product id', cp.ref_product_id);
      console.log('product snapshot length', cp.ref_product_id.length);
      let productsinfo = [];
      cp.ref_product_id.forEach((id) => {
        console.log('Each Id', id);
        var prodref = db.collection('products').doc(id);
        prodref.onSnapshot((squerySnapshot) => {
          console.log('snapshot trigering for product');
          console.log(squerySnapshot.data());
          const data = squerySnapshot.data();
          data.id = squerySnapshot.id;
          (data.name = squerySnapshot.data().pd_name),
            (data.image = squerySnapshot.data().pd_image[0].img_url),
            (data.message = 'Product');
          console.log('products data--', data);
          squerySnapshot.exists ? productsinfo.push(data) : null;
          cp.productsinfo = productsinfo;
          adminslist.push(cp);
          console.log(productsinfo);

          console.log(cp.ref_product_id.length, cp.productsinfo.length);
          if (cp.ref_product_id.length === cp.productsinfo.length) {
            console.log(adminslist);
            console.log(productsinfo);
            setProduct(productsinfo);
            console.log(product);
          }
        });
      });
    });

    db.collection('meetings')
      .where('partner_id', '==', user?.uid)
      .where('is_read', '==', false)
      // .orderBy('created_at', 'desc')
      .onSnapshot((snapshot) => {
        console.log('Onsnapshot triggering', snapshot);
        const data = [];
        snapshot.forEach((doc) => {
          const user = doc.data();
          user.id = doc.id; // Add the document ID to the user object (optional).
          (user.name = doc.data().title),
            (user.image = doc.data().title.charAt(0).toUpperCase()),
            (user.message = 'Meeting');
          data.push(user);
        });
        console.log(data);
        setMeeting(data);
      });
  }, []);
  // console.log(Notify);
  // console.log('training Data', TrainNotify);
  const combinedArray = [...BlogNotify, ...TrainNotify, ...meeting];
  console.log('Data', combinedArray);
  // localStorage.setItem('myData', JSON.stringify(combinedArray));
  const menu = (
    <Menu className='notify-header-message'>
      <Menu.Item className='header'>
        <IntlMessages id='common.notifications' />({combinedArray.length})
      </Menu.Item>
      <Menu.Item>
        <AppScrollbar className='notify-scroll-submenu'>
          <List
            className='notify-list'
            dataSource={combinedArray}
            renderItem={(item) => {
              console.log(item);
              return <NotificationItem key={item.id} item={item} />;
            }}
          />
        </AppScrollbar>
      </Menu.Item>
      <Menu.Item>
        <Button type='primary' className='notify-btn-all'>
          <IntlMessages id='common.viewAll' />
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Badge count={combinedArray.length} overflowCount={10}>
      <Dropdown overlay={menu} className='dropdown' trigger={['click']}>
        <a className='notify-link' onClick={(e) => e.preventDefault()}>
          <span className='notify-icon'>
            <IoIosNotificationsOutline />
          </span>
          <span className='notify-text'>
            <IntlMessages id='common.notifications' />
          </span>
        </a>
      </Dropdown>
    </Badge>
  );
};

export default AppNotifications;

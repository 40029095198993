import {GET_EVENTS, GET_STATUS} from '../../shared/constants/ActionTypes';
import {FETCH_SUCCESS} from '../../shared/constants/ActionTypes';
import {db} from '../../@crema/services/auth/firebase/firebase';
import firebase from 'firebase/app';
// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

export function fetchStatus(user) {
  return (dispatch) => {
    var docRef = firebase
      .firestore()
      .collection('md_status_info')
      .orderBy('status_id', 'asc', user?.uid);
    const statuslist = [];
    docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data(), querySnapshot);
        statuslist.push(doc.data());
      });
    });
    dispatch({type: GET_STATUS, payload: statuslist});
  };
}
export const onGetEvents = (user) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      var docmeetRef = db
        .collection('channel_partner_basic_info')
        .doc(user?.uid);
      console.log(docmeetRef);
      // let quizlist = [];
      docmeetRef.get().then((querySnapshot) => {
        console.log(querySnapshot.data());
      });

      var docRef = firebase
        .firestore()
        .collection('meetings')
        .where('partner_id', '==', user?.uid)
        .where('is_active', '==', true);
      console.log(user);
      // .orderBy('created_at', 'desc');
      const meetings = [];
      console.log(meetings);
      docRef.get().then((querySnapshot) => {
        console.log('---partner query---', querySnapshot.size);
        if (querySnapshot.empty) {
          dispatch({type: GET_EVENTS, payload: meetings});
        } else {
          querySnapshot.forEach((doc) => {
            var meetinfo = doc.data();
            console.log(meetinfo);
            meetinfo.id = doc.id;
            meetinfo.auto_id = doc.id;
            meetinfo.key = doc.id;
            meetinfo.start = new Date(
              doc.data().meeting_scheduled_date +
                ' ' +
                doc.data().meeting_scheduled_time,
            );
            meetinfo.contactinfo = {};
            var subcatref = db
              .collection('md_contacts')
              .doc(doc.data()?.ref_meeting_contact_id);
            subcatref.get().then((squerySnapshot) => {
              meetinfo.contactinfo = squerySnapshot.exists
                ? squerySnapshot.data()
                : {};
              meetinfo.notes = [];
              var notesref = db
                .collection('meeting_notes')
                .where('ref_meeting_id', '==', doc.data()?.auto_id)
                .orderBy('created_at', 'desc');
              notesref.get().then((notesres) => {
                var narr = [];
                notesres.forEach((note) => {
                  narr.push(note.data());
                });
                meetinfo.notes = narr;
                var feedbackref = db
                  .collection('meeting_feedback')
                  .where('ref_meeting_id', '==', doc.data()?.auto_id)
                  .orderBy('created_at', 'desc');
                feedbackref.get().then((feedbackres) => {
                  var farr = [];
                  feedbackres.forEach((feed) => {
                    farr.push(feed.data());
                  });
                  meetinfo.feedback = farr;
                  var followupref = db
                    .collection('meeting_session_followup_later')
                    .where('meeting_master_id', '==', doc.data()?.auto_id)
                    .orderBy('created_at', 'desc');
                  followupref.get().then((followupres) => {
                    var farr = [];
                    followupres.forEach((feed) => {
                      farr.push(feed.data());
                    });
                    meetinfo.followup = farr;
                    meetings.push(meetinfo);
                    console.log(meetings);
                    console.log(meetings.length === querySnapshot.size);
                    if (meetings.length === querySnapshot.size) {
                      console.log(meetings);
                      resolve(meetings);
                      dispatch({type: GET_EVENTS, payload: meetings});
                    }
                  });
                });
              });
            });
          });
        }
      });
    });
  };
};
export const onGetEvents1 = (user) => {
  // return (dispatch) => {
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('meetings')
      .where('partner_id', '==', user?.uid)
      .orderBy('created_at', 'desc');
    console.log(user);
    // .orderBy('created_at', 'desc');
    const meetings = [];
    console.log(meetings);
    docRef.get().then((querySnapshot) => {
      console.log('---partner query---', querySnapshot.size);
      if (querySnapshot.empty) {
        reject(null);
        // dispatch({type: GET_EVENTS, payload: meetings});
      } else {
        querySnapshot.forEach((doc) => {
          var meetinfo = doc.data();
          meetinfo.id = doc.id;
          meetinfo.auto_id = doc.id;
          meetinfo.key = doc.id;
          meetinfo.start = new Date(
            doc.data().meeting_scheduled_date +
              ' ' +
              doc.data().meeting_scheduled_time,
          );
          meetinfo.contactinfo = {};
          var subcatref = db
            .collection('md_contacts')
            .doc(doc.data()?.ref_meeting_contact_id);
          subcatref.get().then((squerySnapshot) => {
            meetinfo.contactinfo = squerySnapshot.exists
              ? squerySnapshot.data()
              : {};
            meetinfo.notes = [];
            var notesref = db
              .collection('meeting_notes')
              .where('ref_meeting_id', '==', doc.data()?.auto_id)
              .orderBy('created_at', 'desc');
            notesref.get().then((notesres) => {
              var narr = [];
              notesres.forEach((note) => {
                narr.push(note.data());
              });
              meetinfo.notes = narr;
              console.log(narr);
              var feedbackref = db
                .collection('meeting_feedback')
                .where('ref_meeting_id', '==', doc.data()?.auto_id)
                .orderBy('created_at', 'desc');
              feedbackref.get().then((feedbackres) => {
                var farr = [];
                feedbackres.forEach((feed) => {
                  farr.push(feed.data());
                });
                meetinfo.feedback = farr;
                var followupref = db
                  .collection('meeting_session_followup_later')
                  .where('meeting_master_id', '==', doc.data()?.auto_id)
                  .orderBy('created_at', 'desc');
                followupref.get().then((followupres) => {
                  var farr = [];
                  followupres.forEach((feed) => {
                    farr.push(feed.data());
                  });
                  meetinfo.followup = farr;
                  meetings.push(meetinfo);
                  console.log(meetings);
                  console.log(meetings.length === querySnapshot.size);
                  if (meetings.length === querySnapshot.size) {
                    console.log(meetings);
                    resolve(meetings);
                    // dispatch({type: GET_EVENTS, payload: meetings});
                  }
                });
              });
            });
          });
        });
      }
    });
  });
  //  }
};
export const getMeetingdoc = (meeting_id) => {
  console.log(meeting_id);
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('meeting_notes')
      .where('ref_meeting_id', '==', meeting_id)
      .where('created_by', '==', firebase.auth().currentUser.uid);
    const meetDoc = [];
    docRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data(), querySnapshot);
          meetDoc.push(doc.data());
          console.log(meetDoc);
          // resolve(meetDoc)
        });
      })
      .then(() => {
        // console.log("Added!");
        resolve(meetDoc);
      })
      .catch((err) => {
        console.log(err);
        reject(err.message);
      });
  });
};
export const getMeetingFeedback = (meeting_id) => {
  console.log(meeting_id);
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('meeting_feedback')
      .where('ref_meeting_id', '==', meeting_id)
      .where('created_by', '==', firebase.auth().currentUser.uid);
    const meetDoc = [];
    docRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data(), querySnapshot);
          meetDoc.push(doc.data());
          console.log(meetDoc);
          // resolve(meetDoc)
        });
      })
      .then(() => {
        // console.log("Added!");
        resolve(meetDoc);
      })
      .catch((err) => {
        console.log(err);
        reject(err.message);
      });
  });
};
// export const  getSelectedMeeting = (meeting_id) => {
//   console.log(meeting_id)
//   return new Promise((resolve, reject) => {
//     var docRef = firebase.firestore().collection("meetings")
//     .where("auto_id", "==", meeting_id)
//     // .where("created_by", "==", firebase.auth().currentUser.uid)
//     const meetDoc = [];
//     docRef.get().then((querySnapshot) => {
//       querySnapshot.forEach((doc) => {
//         // doc.data() is never undefined for query doc snapshots
//         // console.log(doc.id, " => ", doc.data(), querySnapshot);
//         meetDoc.push(doc.data());
//         console.log(meetDoc)
//         // resolve(meetDoc)
//       });
//     }).then(() => {
//       // console.log("Added!");
//       resolve(meetDoc)
//     })
//     .catch((err) => {
//       console.log(err);
//       reject(err.message)
//     });
//   })
// };
export const getSelectedMeeting = (meeting_id) => {
  // return (dispatch) => {
  return new Promise((resolve, reject) => {
    var docRef = firebase
      .firestore()
      .collection('meetings')
      .where('auto_id', '==', meeting_id);
    console.log(meeting_id);
    // .orderBy('created_at', 'desc');
    const meetings = [];
    console.log(meetings);
    docRef.get().then((querySnapshot) => {
      console.log('---partner query---', querySnapshot.size);
      if (querySnapshot.empty) {
        reject(null);
        // dispatch({type: GET_EVENTS, payload: meetings});
      } else {
        querySnapshot.forEach((doc) => {
          var meetinfo = doc.data();
          meetinfo.id = doc.id;
          meetinfo.auto_id = doc.id;
          meetinfo.key = doc.id;
          meetinfo.start = new Date(
            doc.data().meeting_scheduled_date +
              ' ' +
              doc.data().meeting_scheduled_time,
          );
          meetinfo.contactinfo = {};
          var subcatref = db
            .collection('md_contacts')
            .doc(doc.data()?.ref_meeting_contact_id);
          subcatref.get().then((squerySnapshot) => {
            meetinfo.contactinfo = squerySnapshot.exists
              ? squerySnapshot.data()
              : {};
            meetinfo.notes = [];
            var notesref = db
              .collection('meeting_notes')
              .where('ref_meeting_id', '==', doc.data()?.auto_id)
              .orderBy('created_at', 'desc');
            notesref.get().then((notesres) => {
              var narr = [];
              notesres.forEach((note) => {
                narr.push(note.data());
              });
              meetinfo.notes = narr;
              console.log(narr);
              var feedbackref = db
                .collection('meeting_feedback')
                .where('ref_meeting_id', '==', doc.data()?.auto_id)
                .orderBy('created_at', 'desc');
              feedbackref.get().then((feedbackres) => {
                var farr = [];
                feedbackres.forEach((feed) => {
                  farr.push(feed.data());
                });
                meetinfo.feedback = farr;
                var followupref = db
                  .collection('meeting_session_followup_later')
                  .where('meeting_master_id', '==', doc.data()?.auto_id)
                  .orderBy('created_at', 'desc');
                followupref.get().then((followupres) => {
                  var farr = [];
                  followupres.forEach((feed) => {
                    farr.push(feed.data());
                  });
                  meetinfo.followup = farr;
                  meetings.push(meetinfo);
                  console.log(meetings);
                  console.log(meetings.length === querySnapshot.size);
                  if (meetings.length === querySnapshot.size) {
                    console.log(meetings);
                    resolve(meetings);
                    // dispatch({type: GET_EVENTS, payload: meetings});
                  }
                });
              });
            });
          });
        });
      }
    });
  });
  //  }
};
export function changeStatus(event, id) {
  return new Promise((resolve, reject) => {
    console.log(event, id);
    firebase
      .firestore()
      .collection('meetings')
      .doc(id)
      .update({
        ref_meeting_status_Id: parseInt(event),
        is_read: true,
      })
      .then(() => {
        resolve('updated');
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function onAddNewEvent(event, meetdate, meetTime) {
  return new Promise((resolve, reject) => {
    console.log(event);
    const docRef = firebase.firestore().collection('meetings').doc();
    const contactRef = firebase.firestore().collection('md_contacts').doc();
    const meetingdata = {
      auto_id: docRef.id,
      title: event.title,
      partner_id: firebase.auth().currentUser.uid,
      ref_meeting_contact_id: contactRef.id ?? '',
      meeting_scheduled_date: meetdate,
      meeting_scheduled_time: meetTime,
      meeting_scheduled_timestamp:
        firebase.firestore.Timestamp.fromDate(new Date()) ?? '',
      meeting_address: event.meeting_address ?? '',
      meeting_venue: event.meeting_venue ?? '',
      meeting_location_address: '',
      meeting_location: '',
      is_followup_later: event.is_followup_later ?? false,
      ref_meeting_status_Id: 4,
      ref_product_id: event.ref_product_id ?? [],
      ref_status_id: parseInt(event.ref_status_id) ?? 0,
    };
    contactRef
      .set({
        auto_id: contactRef.id,
        contact_name: event.contact_name,
        contact_number: event.contact_number,
        contact_email: event.contact_email.toLowerCase(),
        company: event.company,
        address: event.address,
        ref_status_id: parseInt(event.ref_status_id),
        created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        created_by: firebase.auth().currentUser.uid,
        updated_by: firebase.auth().currentUser.uid,
      })
      .then(() => {
        docRef
          .set({
            ...meetingdata,
            created_at: firebase.firestore.Timestamp.fromDate(new Date()),
            updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
            created_by: firebase.auth().currentUser.uid,
            updated_by: firebase.auth().currentUser.uid,
          })
          .then(() => {
            console.log('Document successfully written!');
            resolve(meetingdata);
          })
          .catch((err) => reject(err));
        console.log('Document successfully written!');
      })
      .catch((err) => reject(err));
  });
}
export function onUpdateEvent(event, meetdate, meetTime) {
  return new Promise((resolve, reject) => {
    console.log(event);
    const docRef = firebase.firestore().collection('meetings').doc(event.id);
    const contactRef = firebase
      .firestore()
      .collection('md_contacts')
      .doc(event.id);
    const meetingdata = {
      title: event.title,
      meeting_scheduled_date: meetdate,
      meeting_scheduled_time: meetTime,
      meeting_scheduled_timestamp:
        firebase.firestore.Timestamp.fromDate(new Date()) ?? '',
      meeting_address: event.meeting_address ?? '',
      meeting_venue: event.meeting_venue ?? '',
      meeting_location_address: event.meeting_location_address ?? '',
      meeting_location: '',
      is_followup_later: event.is_followup_later ?? false,
      ref_meeting_status_Id: parseInt(event.ref_status_id) ?? 0,
      ref_product_id: event.ref_product_id ?? [],
      ref_status_id: parseInt(event.ref_status_id) ?? 0,
      partner_id: event.partner_id,
    };
    contactRef
      .update({
        contact_name: event.contact_name,
        contact_number: event.contact_number,
        contact_email: event.contact_email.toLowerCase(),
        company: event.company,
        address: event.address,
        ref_status_id: parseInt(event.ref_status_id),
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        updated_by: firebase.auth().currentUser.uid,
      })
      .then(() => {
        docRef
          .update({
            ...meetingdata,
            updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
            updated_by: firebase.auth().currentUser.uid,
          })
          .then(() => {
            console.log('Document successfully written!');
            resolve('updated');
          })
          .catch((err) => reject(err));
        console.log('Document successfully written!');
      })
      .catch((err) => reject(err));
  });
}

export function onDeleteEvent(event) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(event);
      var docRef = firebase.firestore().collection('meetings').doc(event);
      docRef
        .delete()
        .then(() => {
          console.log('Document successfully updated!');
          resolve('Document successfully updated!');
          dispatch({type: FETCH_SUCCESS});
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          reject(error);
        });
    });
  };
}

export function onAddNewNotes(event) {
  console.log(event);
  // return (dispatch) => {
  return new Promise((resolve, reject) => {
    // let user = JSON.parse(localStorage.getItem('authUser'));
    var docRef = firebase.firestore().collection('meeting_notes').doc();
    docRef
      .set({
        auto_id: docRef.id,
        notes: event.notes,
        ref_notes_type_id: parseInt(event.ref_notes_type_id),
        ref_meeting_id: event.ref_meeting_id,
        ref_brand_code: event.ref_brand_code ?? '',
        ref_brand_region_code: event.ref_brand_region_code ?? '',
        ref_created_by_role_id: '',
        ref_product_id: event.ref_product_id,
        ref_status_id: 0,
        is_visible: true,
        created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        created_by: firebase.auth().currentUser.uid,
        updated_by: firebase.auth().currentUser.uid,
      })
      .then(() => {
        console.log('Document successfully written!');
        resolve('added');
        // dispatch({type: FETCH_SUCCESS});
      })
      .catch((error) => {
        console.error('Error writing document: ', error);
        reject(error);
      });
  });
}

export function onAddNewFeedback(event, deliveryDate, fileList) {
  console.log('image');
  // return (dispatch) => {
  return new Promise((resolve, reject) => {
    var metadata = {
      contentType: 'image/jpeg',
    };
    var imagearray = [];
    var docRef = firebase.firestore().collection('meeting_feedback').doc();
    var storageRef = firebase.storage().ref();
    console.log(fileList);
    if (event.win_po_image) {
      fileList.forEach((f) => {
        console.log('Win');
        var uploadTask = storageRef
          .child('/meeting_feedback/' + f.name)
          .put(f.originFileObj, metadata);
        console.log(uploadTask);
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          },
          (error) => {
            reject(error);
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              var obj_img = {
                img_url: downloadURL,
              };
              imagearray.push(obj_img);
              console.log(obj_img);
              if (imagearray.length === fileList.length) {
                console.log(imagearray);
                event.win_po_image = imagearray;
                docRef.set({
                  auto_id: docRef.id,
                  ref_meeting_id: event.ref_meeting_id,
                  ref_product_id: event.ref_product_id,
                  meeting_feedback: event.meeting_feedback,
                  ref_feedback_id: parseInt(event.ref_feedback_id),
                  win_delivery_date: deliveryDate,
                  win_extra_info: event.win_extra_info ?? '',
                  win_po_image: event.win_po_image ?? '',
                  reason_for_lost: event.reason_for_lost ?? '',
                  ref_status_id: 0,
                  is_visible: true,
                  created_at: firebase.firestore.Timestamp.fromDate(new Date()),
                  updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
                  created_by: firebase.auth().currentUser.uid,
                  updated_by: firebase.auth().currentUser.uid,
                });
                resolve('added');
              }
            });
          },
        );
      });
    } else {
      console.log('Lost');
      docRef.set({
        auto_id: docRef.id,
        ref_meeting_id: event.ref_meeting_id,
        ref_product_id: event.ref_product_id,
        meeting_feedback: event.meeting_feedback,
        ref_feedback_id: parseInt(event.ref_feedback_id),
        win_delivery_date: deliveryDate,
        win_extra_info: event.win_extra_info ?? '',
        win_po_image: event.win_po_image ?? '',
        reason_for_lost: event.reason_for_lost ?? '',
        ref_status_id: 0,
        is_visible: true,
        created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        created_by: firebase.auth().currentUser.uid,
        updated_by: firebase.auth().currentUser.uid,
      });
      resolve('added');
      // dispatch({type: FETCH_SUCCESS});
    }
  });
  // }
}
export function updateNotesVisiblity(id, value) {
  return new Promise((resolve) => {
    var docRef = firebase.firestore().collection('meeting_notes').doc(id);
    docRef.update({
      is_visible: value,
    });
    resolve('updated');
  });
}

export function updateFeedbackVisiblity(id, value) {
  return new Promise((resolve) => {
    var docRef = firebase.firestore().collection('meeting_feedback').doc(id);
    docRef.update({
      is_visible: value,
    });
    resolve('updated');
  });
}

import {
  GET_BRANDS,
  GET_BRANDS_ADMIN,
  GET_PLAN,
  GET_BLOGS,
  GET_QUIZ,
  GET_TREE_CAT,
  GET_PRODUCTS,
  GET_PRODUCT_FEEDDBACK,
  GET_PARTNERS,
  GET_EVENTS,
  GET_SCORE,
  GET_STATUS,
  GET_SELECTED_SCORE,
} from '../../shared/constants/ActionTypes';

const initialSettings = {
  brands: [],
  adminuser: [],
  quiz: [],
  blogs: [],
  plan: [],
  treecat: [],
  products: [],
  prodfb: [],
  partners: [],
  meetings: [],
  scorecard: [],
  status: [],
  score: []
};

const DataReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        meetings: action.payload,
      };
      case GET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
      case GET_SCORE:
      return {
        ...state,
        scorecard: action.payload,
      };
      case GET_SELECTED_SCORE:
        return {
          ...state,
          score: action.payload,
        };      
    case GET_PRODUCT_FEEDDBACK:
      return {
        ...state,
        prodfb: action.payload,
      };
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
      case GET_PARTNERS:
      return {
        ...state,
        partners: action.payload,
      };
      case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case GET_QUIZ:
      return {
        ...state,
        quiz: action.payload,
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case GET_BRANDS_ADMIN:
      return {
        ...state,
        adminuser: action.payload,
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload,
      };
      case GET_TREE_CAT:
        return {
          ...state,
          treecat: action.payload,
        };
    default:
      return state;
  }
};

export default DataReducer;
